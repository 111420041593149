import Vue from 'vue'
import VueI18n from 'vue-i18n';
import * as uiv from 'uiv'
//import { Splitpanes, Pane } from 'splitpanes'
//import 'splitpanes/dist/splitpanes.css'
import axios from 'axios'
import VueToastify from "vue-toastify";
import InputTag from 'vue-input-tag';
import VueTagsInput from '@johmun/vue-tags-input';
import EuregApi from "./lib/api";

// EUReg components
import InputList from './components/Eureg/InputList.vue';
import InputRows from './components/Eureg/InputRows.vue';
import InputPassword from "./components/Eureg/InputPassword";
import DomainDetails from "./components/Eureg/Domain/Details";
import DomainMailboxes from "./components/Eureg/Domain/Mailboxes";
import LoadingPanel from "./components/Eureg/LoadingPanel";

require('../webroot/assets/eureg2019/css/main.scss')
require('./mixins.js');

const isIp = require('is-ip');

window.axios = axios
window.isIp = isIp

// register moment object globally
const moment = require('moment')
require("moment-duration-format")
require('moment/locale/ro')

moment.locale('en');
window.moment = moment

// HTML pages have the CSRF token in meta header
let csrfToken = document.head.querySelector('meta[name="csrf-token"]');

if (csrfToken) {
    window.axios.defaults.headers.common['X-CSRF-TOKEN'] = csrfToken.content;
    if (process.env.NODE_ENV !== 'production') {
        console.log("Found CSRF Token in Meta: " + csrfToken.content);
    }
} else {
    if (process.env.NODE_ENV !== 'production') {
        console.error('CSRF token not found');
    }
}


window.GlobalVue = function(el, pageMixins) {
    Vue.use(uiv);
    Vue.use(VueToastify, { theme: "light" });
    Vue.use(VueI18n);
    Vue.use(require('vue-moment'), {
        moment
    })
    Vue.component('input-tag', InputTag);
    Vue.component('eureg-input-list', InputList);
    Vue.component('eureg-input-rows', InputRows);
    Vue.component('eureg-input-password', InputPassword);
    Vue.component('eureg-domain-details', DomainDetails);
    Vue.component('eureg-domain-mailboxes', DomainMailboxes);
    Vue.component('eureg-loading-panel', LoadingPanel);

    const i18n_messages = {
        en: {
            app: {
                loading: "Loading data",
                status: "Status",
            },
            button: {
                save_changes: "Save changes",
                cancel: "Cancel",
                edit: "Edit",
                set_new_password: "Set a new password",
                executing: "Executing",
            },
            general: {
                full_name: "Full Name",
                password: "Password",
                new_password: "New Password",
            },
            mail: {
                priority: "Priority",
                server_name: "Server name",
                eg_server_name: "eg. mail.isp.com",
                x_out_of_y: "{0} out of {1} mailboxes created",
                username_invalid: "Username is invalid",
                username_required: "Username is required",
                password_too_short: "The password is too short",
            },
            mailbox: {
                create_mailbox: "Create mailbox",
                configure_client: "Configure your Mail Client",
                no_mailboxes: "No mailboxes were created",
                new_mailbox: "New mailbox address",
                mailbox: "Mailbox",
                storage: "Storage",
                active: "Active",
                suspended: "Suspended",
                deleted: "Deleted",
                details: "Mailbox details",
                minimum_8chars: "Minimum 8 characters",
                tooltip_username: "Minimum 2 characters:<br>letters (a-z)<br>digits (0-9)<br>dot (.)<br>underscore (_)",
                no_quota: "No quota",
                cannot_end_special: "Cannot end with a special character",
                must_start_letter: "Must start with a letter",
            },
            placeholder: {
                eg_user: "eg. user",
                eg_john_smith: "eg. John Smith",
            }
        },

        ro: {
            app: {
                loading: "Se încarcă datele",
                status: "Stare",
            },
            button: {
                save_changes: "Salvează modificările",
                cancel: "Anulează",
                edit: "Modifică",
                set_new_password: "Setează o parolă nouă",
                executing: "Se execută",
            },
            general: {
                full_name: "Nume Complet",
                password: "Parolă",
                new_password: "Parolă nouă",
            },
            mail: {
                priority: "Prioritate",
                server_name: "Nume server",
                eg_server_name: "ex. mail.isp.ro",
                x_out_of_y: "{0} din {1} casuțe create",
                username_invalid: "Numele de utilizator este invalid",
                username_required: "Numele de utilizator este necesar",
                password_too_short: "Parola este prea scurtă",
            },
            mailbox: {
                create_mailbox: "Creează căsuță",
                configure_client: "Configurează Clientul de email",
                no_mailboxes: "Nu au fost create căsuțe de email",
                new_mailbox: "Căsuță de email nouă",
                mailbox: "Căsuță de email",
                storage: "Spațiu de stocare",
                active: "Activă",
                suspended: "Suspendată",
                deleted: "Ștearsă",
                details: "Detalii căsuță email",
                minimum_8chars: "Minim 8 caractere",
                tooltip_username: "Minim 2 caractere:<br>litere (a-z)<br>cifre (0-9)<br>punct (.)<br>underscore (_)",
                no_quota: "Fără limită",
                cannot_end_special: "Nu se poate termina cu un caracter special",
                must_start_letter: "Trebuie să înceapă cu o literă",
            },
            placeholder: {
                eg_user: "de ex. ion.popescu",
                eg_john_smith: "de ex. Ion POPESCU",
            }
        }
    };

    const i18n = new VueI18n({
        locale: window.$eureg ? window.$eureg.lang : "en",
        messages: i18n_messages,
    });

    Vue.prototype.$api = new EuregApi();

    return  new Vue({
        el: el,
        data: {
            config: {
                locale: window.$eureg ? window.$eureg.lang : "en",
            },
            /* generic data for the app */
            app: {
                isLoading: window.sharedData.app.isLoading,
                lang: window.$eureg ? window.$eureg.lang : "en",
                isMobile: false,
                windowWidth: 0,
            },
        },
        components: { /*Splitpanes, Pane,*/ VueTagsInput },
        mixins: pageMixins,

        computed: {
            isLoading: {
                get: function() {
                    return this.app.isLoading;
                },

                set: function(newVal) {
                    this.app.isLoading = newVal;
                }
            },
            appIsMobile: function() {
                return this.app.isMobile;
            }
        },
        beforeDestroy: function() {
            /*
            if (typeof window !== 'undefined') {
                window.removeEventListener('resize', this.onResize, { passive: true })
            }
             */
        },
        created: function() {
            this.checkAgentSignature();
        },
        mounted: function() {
            //this.onResize()
            //window.addEventListener('resize', this.onResize, { passive: true })

            if (process.env.NODE_ENV !== 'production') {
                console.log("[page.js] Vue app mounted on ", this.$el)
            }
            /* We finished loading, so we are ready for business */
            this.isLoading = false;
            this.isIp = isIp

            this.config.locale = this.app.lang;
            this.$i18n.locale = this.app.lang;
        },
        methods: {
            checkAgentSignature() {
                let check = false;
                (function(a) {
                    if (
                        /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                            a
                        ) ||
                        /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                            a.substr(0, 4)
                        )
                    )
                    check = true;
                })(navigator.userAgent || navigator.vendor || window.opera);
                if (process.env.NODE_ENV !== 'production') {
                    console.log("[page.js] Vue instance created on mobile ", check)
                }
                this.$set(this.app, 'isMobile', check);
            },
            onResize () {
                this.$set(this.app, 'windowWidth', window.innerWidth);
                this.$set(this.app, 'isMobile', this.app.windowWidth < 600);
            }
        },
        i18n
    });
}
