<template>
  <div>
      <modal :transition="0" v-model="modalCreateMailbox" :title="$t('mailbox.details')">
          <input type="password" value="" autocomplete="new-password" style="display: none;">
          <template v-if="new_mailbox.address">
              <label>{{ $t('mailbox.mailbox') }}</label>
              <input type="text" disabled readonly :value="new_mailbox.address" class="form-control input-lg">
          </template>
          <template v-else>
              <label>{{ $t('mailbox.new_mailbox') }}
                <i class="fa fa fa-question-circle text-primary" v-tooltip="$t('mailbox.tooltip_username')"></i>
              </label>
                    <div class="input-group input-group-lg" v-bind:class="{'has-error' : new_mailbox.errors.username }">
                        <input @input="onInput" v-model="new_mailbox.username" type="text" :placeholder="$t('placeholder.eg_user')" class="form-control">
                            <div class="input-group-addon">
                              <span>@{{ remote.domain }}</span>
                            </div>
                    </div>
              <span v-if="new_mailbox.errors.username" class="text-red" v-text="new_mailbox.errors.username"></span>
          </template>
          <br>

        <label>{{ $t('general.full_name') }}</label>
        <input @input="onInput" v-model="new_mailbox.name" type="text" :placeholder="$t('placeholder.eg_john_smith')" class="form-control input-lg">
        <br>

        <label>{{ $t('general.password') }}
          <i class="fa fa fa-question-circle text-primary" v-tooltip="$t('mailbox.minimum_8chars')"></i>
        </label>
        <template v-if="new_mailbox.address && !new_mailbox.new_password">
          <div class="is-size-8">
            <a href="#" class="text-primary" @click.prevent="new_mailbox.new_password = true; new_mailbox.password = null;">{{ $t('button.set_new_password') }}</a>
          </div>
        </template>
        <template v-else>
          <eureg-input-password @input="onInput" class="input-group-lg" v-bind:class="{'has-error' : new_mailbox.errors.password }" v-model="new_mailbox.password"></eureg-input-password>
          <span v-if="new_mailbox.errors.password" class="text-red" v-text="new_mailbox.errors.password"></span>
          <div v-if="new_mailbox.address && new_mailbox.new_password">
            <a href="#" class="text-primary" @click.prevent="new_mailbox.new_password = false; new_mailbox.has_changes = false; new_mailbox.password = null;">{{ $t('button.cancel') }}</a>
          </div>
        </template>
        <br>

        <!--
        <label>{t}Recovery Email (optional){/t}</label>
        <input v-model="new_mailbox.recovery" type="text" placeholder="{t}eg. another@gmail.com{/t}" class="form-control input-lg">
        <br>
        -->

        <div class="text-center">
            <btn type="primary" :disabled="!isValidNewMailbox || isLoading" @click="createUpdateMailbox">
                <span v-if="isLoading"><i class="fas fa-circle-notch fa-spin fa-fw"></i>&nbsp;{{ $t('button.executing') }}</span>
                <span v-else-if="new_mailbox.address">{{ $t('button.save_changes') }}</span>
                <span v-else>{{ $t('mailbox.create_mailbox')}}</span>
            </btn>
        </div>

        <div slot="footer">
          <btn @click="modalCreateMailbox=false">{{ $t('button.cancel') }}</btn>
        </div>
      </modal>

      <div class="row medium-margin">
          <div class="col-xs-12" v-if="isLoading">
              <eureg-loading-panel></eureg-loading-panel>
          </div>
          <div v-else class="col-xs-12">
              <span class="h3 text-green no-margin pl-2" v-text="$t('mail.x_out_of_y', [numberActiveMailboxes, numberMaxMailboxes])"></span>
              <br><br>
                  <template v-if="mailboxes && mailboxes.length" >

                    <table class="table table-striped is-size-7">
                      <thead>
                      <tr>
                        <th class="text-left">{{ $t('mailbox.mailbox') }}</th>
                        <th class="text-center">{{ $t('app.status') }}</th>
                        <th class="text-right">{{ $t('mailbox.storage') }}</th>
                        <th>&nbsp;</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(mailbox,idx) in mailboxes">
                        <td class="vertical-align-middle"><span v-text="mailbox.mailbox"></span><br>
                          <span class="small text-muted" v-text="mailbox.name"></span>
                        </td>
                        <td class="text-center vertical-align-middle"><span v-if="mailbox.active" class="text-green">{{ $t('mailbox.active') }}<br>
                                                  <a :href="'https://web.mail.ro/?_user=' + mailbox.mailbox" target="_blank" class="is-size-8">Web mail</a>
                                              </span>
                          <span v-else-if="!mailbox.active" class="text-muted">{{ $t('mailbox.suspended') }}</span>
                          <span v-else-if="mailbox.deleted" class="text-red">{{ $t('mailbox.deleted') }}</span>
                        </td>
                        <td class="text-right vertical-align-middle">
                          <span v-if="mailbox.quota > 0" v-text="mailbox.quota + 'GB'"></span>
                          <span v-else v-text="$t('mailbox.no_quota')"></span>
                          <!--
                          <div>
                            <button class="btn-primary btn-md p-0 btn-link"><i class="fa fa-fw fa-plus"></i>{t}Add storage{/t}</button>
                          </div>
                          -->
                        </td>
                        <td class="text-right vertical-align-middle">
                          <button @click="editMailbox(idx)" class="btn-link is-size-8"><i class="fa fa-fw fa-edit pr-1"></i>{{ $t('button.edit') }}</button>
                        </td>
                      </tr>
                      </tbody>
                    </table>

                    <div class="row">
                      <div class="col-xs-6">
                        <button :disabled="!canAddMailboxes" @click="createMailbox" class="btn btn-primary"><i class="fa fa-fw fa-plus"></i>{{ $t('mailbox.create_mailbox')}}</button>
                      </div>
                      <div class="col-xs-6 text-right">
                        <a href="https://www.eureg.ro/ro/ajutor/mail/setari-client-mail" target="_blank">{{ $t('mailbox.configure_client')}}</a>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                      <div class="panel panel-default is-flex flex-dir-column" style="min-height: 200px;">
                          <div class="panel-body text-center" style="margin-top: auto; margin-bottom: auto;">
                              <p class="is-size-7">
                                {{ $t('mailbox.no_mailboxes')}}
                              </p>
                            <button @click="createMailbox" class="btn btn-primary">{{ $t('mailbox.create_mailbox')}}</button>
                          </div>
                      </div>
                  </template>
          </div>
      </div>


  </div>
</template>

<script>
export default {
  name: 'eureg-domain-mailboxes',
  props: {
    value: {
      type: Array,
      default: () => []
    },

    domain: {
      type: Object,
      default: () => {}
    },
  },

  watch: {
    value: function(newVal, oldVal) {
      this.$set(this, 'mailboxes', newVal);
    },

    domain: function(newVal, oldVal) {
      this.$set(this, 'remote', newVal);
    }
  },

  data() {
      return {
          isLoading: false,
          mailboxes: this.value,
          remote: this.domain,

          new_mailbox: {
              has_changes: false,
              username: null,
              name: null,
              password: null,
              new_password: false,
              recovery: null,
              errors: {},
          },
          modalCreateMailbox: false,
      }
  },

  computed: {
      isValidLocalPart: function() {
          if (!this.new_mailbox.username) {
            this.$set(this.new_mailbox.errors, 'username', this.$t('mail.username_required'))
            return null;
          }

          let $username = this.new_mailbox.username.toLowerCase();

          if (!$username.length) {
            this.$set(this.new_mailbox.errors, 'username', this.$t('mail.username_required'))
            return null;
          }

          // only letters, numbers and special character (.) and (_) allowed
          if (!$username.match(/^[a-z0-9._]+$/)) {
            return false;
          }

          // no 2 special characters in a row
          if ($username.match(/[._]{2}/)) {
            return false;
          }

          // must start with a letter
          if (!$username.match(/^[a-z]/)) {
            this.$set(this.new_mailbox.errors, 'username', this.$t('mailbox.must_start_letter'))
            return null;
          }

          // must end with a letter or digit
          if (!$username.match(/[a-z0-9]$/)) {
            this.$set(this.new_mailbox.errors, 'username', this.$t('mailbox.cannot_end_special'))
            return null;
          }
          return true;
      },

      isValidNewMailbox: function() {
          if (process.env.NODE_ENV !== 'production') {
            console.log("isValidNewMailbox called");
          }

          this.$set(this.new_mailbox.errors, 'username', null);
          this.$set(this.new_mailbox.errors, 'password', null);

          // nothing changed, no save button
          if (!this.new_mailbox.has_changes) {
              return false;
          }

          let result = true;

          if (!this.new_mailbox.address) {
              if (this.isValidLocalPart === null) {
                result = false;
              } else if (this.isValidLocalPart === false) {
                this.$set(this.new_mailbox.errors, 'username', this.$t('mail.username_invalid'))
                result = false;
              }
          }

          if ((!this.new_mailbox.address || this.new_mailbox.new_password)
              && ((!this.new_mailbox.password
                  || this.new_mailbox.password.length < 8))) {
            this.$set(this.new_mailbox.errors, 'password', this.$t('mail.password_too_short'))
            result = false;
          }

          return result;
      },


      numberActiveMailboxes: function() {
          return this.mailboxes ? this.mailboxes.length : 0;
      },

      numberMaxMailboxes: function() {
          if (!this.remote) {
              return this.numberActiveMailboxes;
          }

          if (typeof this.remote.available_mailboxes !== 'undefined') {
              let available = parseInt(this.remote.available_mailboxes);
              return available + this.numberActiveMailboxes;
          }

          if (typeof this.remote.max_mailboxes !== 'undefined') {
              return parseInt(this.remote.max_mailboxes);
          }

          return this.numberActiveMailboxes;
      },

      canAddMailboxes: function() {
          return this.numberActiveMailboxes < this.numberMaxMailboxes;
      },
  },

  methods: {
      onInput: function(ev) {
          this.new_mailbox.has_changes = true;
      },

      async loadMailboxes() {

          if (process.env.NODE_ENV !== 'production') {
              console.log("Mailboxes.loadMailboxes method");
          }
          try {
              this.isLoading = true;
              let apiResult = await this.$api.get("/domain/" + this.remote.domain + "/mailboxes");
              this.$set(this, 'mailboxes', apiResult.data.mailboxes);
              this.remote = _.merge(this.remote, apiResult.data);
          } catch(e) {
          } finally {
              this.isLoading = false;
          }
      },
      async createUpdateMailbox() {
          if (process.env.NODE_ENV !== 'production') {
            console.log("createMailboxCalled called:");
          }
          try {
              let data = {};
              let address = null;

              if (this.new_mailbox.address) {
                address = this.new_mailbox.address;
              } else {
                address = this.new_mailbox.username + "@" + this.remote.domain;
              }
              data.name = this.new_mailbox.name;
              if (!this.new_mailbox.address
                  || (this.new_mailbox.address && this.new_mailbox.new_password)) {
                data.password = this.new_mailbox.password;
              }
              // recovery email
              data.recovery = this.new_mailbox.recovery;

              this.isLoading = true;
              let apiResult = null;

              if (this.new_mailbox.address) {
                apiResult = await this.$api.put("/mailbox/" + address, data);
              } else {
                apiResult = await this.$api.post("/mailbox/" + address, data);
              }
              let msg = apiResult.data.message;

              this.$vToastify.success({
                canTimeout: true,
                hideProgressbar: true,
                duration: 2000,
                title: "Success!",
                body: "<span class=\"h3\">" + msg + "</span>",
                type: "success"
              });
              this.loadMailboxes();
              this.modalCreateMailbox = false;
          } catch (e) {
              let msg = e.response.data.message;

              this.$vToastify.error({
                  canTimeout: true,
                  hideProgressbar: true,
                  duration: 2000,
                  title: "Error!",
                  body: "<span class=\"h3\">" + msg + "</span>",
                  type: "error"
              });
          } finally {
              this.isLoading = false;
          }
      },

      editMailbox(id) {
          let data = {
              has_changes: false,
              errors: {},
              username: null,
              address: this.mailboxes[id].mailbox,
              name: this.mailboxes[id].name,
              password: null,
              new_password: null,
              recovery: this.mailboxes[id].recovery,
          };
          this.new_mailbox = Object.assign({}, data);
          this.modalCreateMailbox = true;
      },

      createMailbox() {
          let data = {
              has_changes: false,
              errors: {},
              username: null,
              name: null,
              password: null,
              recovery: null,
          }
          this.new_mailbox = Object.assign({}, data);
          this.modalCreateMailbox = true;
      }
  },

  created: function() {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Mailboxes.created method");
    }

  },

  mounted: function() {
    if (process.env.NODE_ENV !== 'production') {
      console.log("Mailboxes.mounted method");
    }
    this.loadMailboxes();
  },


}
</script>